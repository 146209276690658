export const MOCK_DATA = [
  {
    id: 1,
    title: 'Adevinta Spain',
    subTitle: 'Frontend Engineer',
    from: new Date('2019-09-01'),
    isCurrent: true,
    location: 'Barcelona, Spain',
    description:
      "At Adevinta I'm building Fotocasa and Habitaclia new features to help alot of users to find their home. I started focused on improve the experience for our agencies and now I'm focused to improve the seeker experience. That helps me to learn all funnel and suggest new features. Also, I help the company to improve their hiring process and also I've participated ad several interview process to help the company to find the best candidates."
  },
  {
    id: 2,
    title: 'Pasiona',
    subTitle: 'Software Developer',
    from: new Date('2014-09-01'),
    to: new Date('2019-09-01'),
    location: 'Barcelona, Spain',
    description:
      'I continued growing my .NET skills but I started to focus me on Frontend side. My first Javascript libraries was AngularJS and KnockOut combined with HTML5 and CSS3. But I felt in love with Frontend side when I met React. I learned some deployment skills with Azure and build containerized applications with Docker. I build applications for Traveloprt, Desigual, Mitsubishi, Hutchinson, and more!'
  },
  {
    id: 3,
    subTitle: 'Software Developer',
    title: 'Ingens-Networks',
    from: new Date('2012-10-01'),
    to: new Date('2014-09-01'),
    location: 'Barcelona, Spain',
    description:
      "I started my first job as a Software Developer building .NET (MVC, WebAPI and WebForms) applications. To improve the applications y used jQuery, AJAX, HTML5 and CSS3. Also I created Drupal CMS and PrestaShop templates. The team was 3 developers and our main goal was maintain the reporting system website for our systems team. We combined this project with other projects for our clients. For example, we build a Wine's company landing, a social network for discos or an e-commerce to sell audio-guides."
  }
]
