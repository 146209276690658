import {HistoryList} from '@/components/common/HistoryList/index.js'
import Section from '@/components/common/Section/index.js'

import {MOCK_DATA} from './settings.js'
import {UIExperience} from './styles/ui.js'

export default function Experience() {
  return (
    <Section
      title="My Experience"
      description={
        'I started my career building and deploying .NET applications. Step by step I was moving to Javascript stack and nowadays I consider a Javascript FullStack Engineer.'
      }
    >
      <UIExperience>
        <HistoryList list={MOCK_DATA} />
      </UIExperience>
    </Section>
  )
}
