import styled from 'styled-components'
import {fadeInUpAnimation} from '@/styles/animations/fadeInUp.js'

export const UIExperience = styled.article`
  animation-name: ${fadeInUpAnimation};
  animation-duration: 1s;
  animation-fill-mode: both;

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`
